import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"

class Header extends Component {
  state = {
    scrolled: false,
    sideDrawerOpen: false,
  }

  componentDidMount() {
    window.addEventListener("scroll", this.navOnScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.navOnScroll)
  }
  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen }
    })
  }
  backdroupClickHandler = () => {
    this.setState({ sideDrawerOpen: false })
  }
  navOnScroll = () => {
    if (window.scrollY > 20) {
      this.setState({ scrolled: true })
    } else {
      this.setState({ scrolled: false })
    }
  }
  render() {
    const { scrolled } = this.state
    return (
      <header>
        <nav>
          <div className={`nav-container ${scrolled ? `scroll` : ``}`}>
            <div className="brand-name">
              <Link to="/">
                <p>{this.props.siteTitle}</p>
              </Link>
            </div>
            <div
              className="hamburger-icon"
              onClick={() => this.drawerToggleClickHandler()}
            >
              <div className="line" />
              <div className="line" />
              <div className="line" />
            </div>
            <ul className="nav-links">
              <li>
                <Link to="/category/javascript">JavaScript</Link>
              </li>
              <li>
                <Link to="/category/node-js">Node Js</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
            </ul>
          </div>
        </nav>

        <nav
          className={`side-drawer ${this.state.sideDrawerOpen ? `open` : ``}`}
        >
          <ul>
            <li>
              <Link to="/category/javascript">JavaScript</Link>
            </li>
            <li>
              <Link to="/category/node-js">NodeJs</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
          </ul>
        </nav>
        {this.state.sideDrawerOpen ? (
          <div
            className="backdrop"
            onClick={() => this.backdroupClickHandler()}
          />
        ) : (
          ""
        )}
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
