import React, { Component } from "react"
import { Link } from "gatsby"

class PageTitle extends Component {
  constructor() {
    super()
    this.pageHeader = React.createRef()
  }
  componentDidMount() {
    window.addEventListener("scroll", this.navOnScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.navOnScroll)
  }

  navOnScroll = () => {
    let target = this.pageHeader.current
    let scrolled = window.pageYOffset
    let rate = scrolled * 0.2
    if (rate < 170) {
      target.style.transform = `translate3d(0px, ${rate}px, 0px)`
    }
  }
  toCapitalize(authorName) {
    let splitStr = authorName.toLowerCase().split("-")
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    return splitStr.join(" ")
  }
  render() {
    return (
      <div className="page-header" ref={this.pageHeader}>
        <div className="site-title-container">
          <h1 className="site-title">
            {this.toCapitalize(this.props.pageTitle)}
          </h1>
          {this.props.author_name !== undefined ? (
            <h4 className="author">
              Published By{" "}
              <Link
                to={`/author/${this.props.author_name
                  .split(" ")
                  .join("")
                  .toLowerCase()}`}
              >
                {this.toCapitalize(this.props.author_name)}
              </Link>{" "}
              on {this.props.published_date}
            </h4>
          ) : (
            ""
          )}
        </div>
      </div>
    )
  }
}

export default PageTitle
