import React from "react"

import Layout from "../components/layout"
import PageTitle from "../components/PageTitle"

const NotFoundPage = () => (
  <Layout>
    <PageTitle pageTitle={"Sorry! Page Not Found."} />
    <div className="post-background">
      <div className="post-container">
        <div className="post-item">
          <p>
            The page you were looking for was either not found or doesn't exist.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
